<template>
	<div class="content">
		<div :class="[!fileData ? 'full' : '', 'select-file']">
			<label for="fileSelector" :class="['upload-file', canSignDocuments ? '' : 'disabled']"
				:disabled="!canSignDocuments"><vs-icon icon="upload_file" :size="fileData ? 'small' : 'large'"
					color="success"></vs-icon>{{ i18n.getLocalised('button.selectFile') }}</label>
			<input type="file" id="fileSelector" accept=".pdf" @change="fileSelected" style="display: none"
				:disabled="!canSignDocuments" />
			<div class="footer-actions">
				<!-- <vs-checkbox color="success" v-model="encryptFile" v-if="fileData" icon="">{{
					i18n.getLocalised('encryptFile')
				}}</vs-checkbox> -->
				<vs-button color="primary" type="border" @click="requestUploadFile" v-if="fileData" :disabled="!canSign">{{
					i18n.getLocalised('button.signFile')
				}}</vs-button>
			</div>
		</div>
		<div v-if="fileData" class="file-reader" v-smoothscrollbar="{
			options: { damping: 0.1, alwaysShowTracks: true },
		}">
			<div style="height: 100%; padding: 0 1rem;">
				<pdf-signable :url="fileData" :digitalSignature="digitalSignature" @signaturesChanged="signaturesChanged"
					@onDocumentLoadError="documentLoadError"></pdf-signable>
			</div>
		</div>
		<vs-prompt type="confirm" color="success" :title="i18n.getLocalised('popup.setEncryptionPassword')"
			:active.sync="encryptionPasswordPopup" :is-valid="!!filePassword"
			:accept-text="i18n.getLocalised('button.confirm')" :cancel-text="i18n.getLocalised('button.cancel')"
			@accept="confirmFilePassword" @cancel="encryptionPasswordPopup = false">
			<div class="pop-up-content">
				<vs-input placeholder="Password" v-model="filePassword" type="password" />
			</div>
		</vs-prompt>
		<vs-prompt type="confirm" color="success" :title="i18n.getLocalised('popup.certificatePassword')"
			:active.sync="pinPopupActive" :is-valid="!!clientPin && allowClientPin"
			:accept-text="i18n.getLocalised('button.confirm')" :cancel-text="i18n.getLocalised('button.cancel')"
			@accept="confirmPin" @cancel="pinPopupActive = false">
			<div class="pop-up-content">
				<vs-input placeholder="Certificate password" v-model="clientPin" type="password" />
			</div>
		</vs-prompt>
		<vs-prompt type="confirm" color="success" :title="i18n.getLocalised('popup.otp')" :active.sync="otpPopupActive"
			:is-valid="!!otp && !uploading" :accept-text="i18n.getLocalised('button.confirm')"
			:cancel-text="i18n.getLocalised('button.cancel')" @accept="signDocument" @cancel="otpPopupActive = false">
			<!-- <vs-popup :title="i18n.getLocalised('popup.otp')" :active.sync="otpPopupActive"> -->
			<div class="pop-up-content otp-popup">
				<div>
					<vs-input placeholder="OTP" v-model="otp" type="password" />
					<vs-button color="dark" type="flat" @click="confirmPin" :disabled="!allowResend">{{
						i18n.getLocalised('button.resend') }} {{ allowResend ? '' : '(' + resendOtpTimer + ')'
	}}</vs-button>
				</div>
				<div>
					<vs-checkbox color="success" v-model="rememberMe">{{
						i18n.getLocalised('popup.otp.remember')
					}}</vs-checkbox>
				</div>
			</div>
			<!-- </vs-popup> -->
		</vs-prompt>
	</div>
</template>

<script>
import { saveAs } from 'file-saver';
import PdfSignable from '../components/PdfSignable.vue';
import { DocumentsService } from '../services/documents-service';
import { UserService } from '../services/user-service';
import { EventBus, State } from '../services/events-service.js';
import { events } from '../events.js';

export default {
	components: { PdfSignable },
	data: function () {
		return {
			canSignDocuments: false,
			fileName: '',
			fileData: null,
			file: null,
			signatures: [],
			digitalSignature: {
				title: 'Signature',
				firstName: '',
				lastName: '',
			},
			clientPin: '',
			otp: '',
			sessionId: '',
			pinPopupActive: false,
			otpPopupActive: false,
			uploading: false,
			allowClientPin: true,
			canSign: false,
			resendOtpTimer: 0,
			allowResend: false,
			encryptFile: false,
			encryptionPasswordPopup: false,
			filePassword: '',
			rememberMe: false,
		};
	},
	watch: {
		resendOtpTimer: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.resendOtpTimer--;
					}, 1000);
				} else {
					this.allowResend = true;
				}
			},
		},
		// encryptFile: {
			// handler(newVal) {
				// localStorage.setItem('encryptFile', newVal ? '1' : '0');
			// },
		// },
	},
	mounted() {
		console.log('State', State);
		const user = UserService.getUser();
		this.digitalSignature.firstName = user.firstName;
		this.digitalSignature.lastName = user.lastName;
		this.canSignDocuments = State.userHasCertificates;
		this.encryptFile = false; //localStorage.getItem('encryptFile') ?? '0' == '1';
		EventBus.$on(events.profileLoaded, () => {
			console.log('profile loaded');
			this.canSignDocuments = State.userHasCertificates;
		});
	},
	methods: {
		async fileSelected(e) {
			try {
				this.file = e.target.files[0];
				this.fileName = this.file.name;
				const reader = new FileReader();
				reader.readAsDataURL(this.file);
				reader.onload = () => {
					this.fileData = reader.result;
				};
			} catch (e) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('INVALID_FILE'),
					color: 'danger',
					position: 'bottom-center',
				});
			}
		},
		requestUploadFile() {
			if (this.file && this.signatures && this.signatures.length > 0) {
				if (this.encryptFile) this.encryptionPasswordPopup = true;
				else this.pinPopupActive = true;
			} else {
				this.$vs.notify({
					title: 'Invalid',
					text: 'Please select a file and sign before uploading',
					color: 'danger',
					position: 'bottom-center',
				});
			}
		},
		confirmFilePassword() {
			this.encryptionPasswordPopup = false;
			this.pinPopupActive = true;
		},
		async confirmPin() {
			this.allowClientPin = false;
			this.resendOtpTimer = 60;
			this.allowResend = false;
			try {
				const uploadResult = await DocumentsService.requestUploadFile(this.fileName, this.file, this.signatures, this.clientPin);
				if (uploadResult.status == 200) {
					this.pinPopupActive = false;
					this.otpPopupActive = true;
					const session = uploadResult.data;
					this.sessionId = session.sessionId;
				} else if (uploadResult.status == 202) {
					this.pinPopupActive = false;
					this.otpPopupActive = false;
					this.otp = "000000";
					const session = uploadResult.data;
					this.sessionId = session.sessionId;
					this.signDocument();
				}
			} catch {
				this.pinPopupActive = false;
				this.otpPopupActive = false;
				this.clientPin = null;
				this.otp = null;
				this.$vs.notify({
					title: 'Error',
					text: this.i18n.getLocalised('INVALID_REQUEST'),
					color: 'danger',
					position: 'bottom-center',
				});
			} finally {
				this.allowClientPin = true;
			}
		},
		async signDocument() {
			if (this.otp) {
				try {
					this.uploading = true;
					this.$vs.loading({ type: 'border' });
					const uploadResult = await DocumentsService.uploadFile(
						this.fileName,
						this.file,
						{
							signatures: this.signatures,
							clientPin: this.clientPin,
							encryptFile: this.encryptFile,
							encryptionPassword: this.filePassword,
						},
						{
							sessionId: this.sessionId,
							otpCode: this.otp,
							rememberMe: this.rememberMe,
						}
					);
					this.pinPopupActive = false;
					this.otpPopupActive = false;

					this.downloadFile(uploadResult.data, this.fileName);

					this.$vs.notify({
						title: 'Success',
						text: this.i18n.getLocalised('FILE_SIGNED'),
						color: 'success',
						position: 'bottom-center',
					});
					this.reset();
				} catch (e) {
					this.pinPopupActive = false;
					this.otpPopupActive = false;
					let message = '';
					if (e.response && e.response.data) {
						const reader = new FileReader();
						reader.readAsText(e.response.data);
						reader.onload = () => {
							const response = JSON.parse(reader.result);
							message = this.i18n.getLocalised(response.code);
							this.$vs.notify({
								title: 'Error',
								text: message,
								color: 'danger',
								position: 'bottom-center',
							});
						};
					} else {
						message = this.i18n.getLocalised('INVALID_REQUEST');
						this.$vs.notify({
							title: 'Error',
							text: message,
							color: 'danger',
							position: 'bottom-center',
						});
					}
				} finally {
					this.uploading = false;
					this.$vs.loading.close();
					this.clientPin = '';
					this.otp = '';
				}
			}
		},
		downloadFile(blobFile, fileName) {
			var blob = new Blob([blobFile], { type: 'application/octet-stream' });
			saveAs(blob, fileName);
		},
		async signaturesChanged(signatures) {
			this.signatures = signatures;
			this.canSign = !!this.signatures?.find((f) => f.signatures.length > 0);
		},
		documentLoadError(err) {
			console.error(err);
			this.fileName = '';
			this.fileData = null;
			this.$vs.notify({
				title: 'Invalid',
				text: this.i18n.getLocalised('INVALID_FILE'),
				color: 'danger',
				position: 'bottom-center',
			});
		},
		reset() {
			this.fileName = '';
			this.fileData = null;
			this.file = null;
			this.signatures = [];
		},
	},
};
</script>

<style lang="scss" scoped>
.content {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: flex-start;
	overflow: hidden;
	height: 100%;
}

.file-reader {
	flex: 4;
	overflow-y: auto;
	height: 100%;
}

.select-file {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 15%;
	height: 100%;
	padding: 0 1rem;
}

.select-file.full {
	width: 100%;
	justify-content: center;
	align-items: center;

	>label {
		display: flex;
		flex-direction: column;
		font-size: 1.2rem;
		padding: 2rem;
		border: 2px solid transparent;
		border-radius: 1rem;
		transition: box-shadow 0.3s ease-in-out;
	}
}

.upload-file {
	display: flex;
	align-items: center;
	font-size: 0.8rem;

	&:hover:not(.disabled) {
		-webkit-box-shadow: inset 0px 0px 55px 10px rgba(222, 222, 222, 1);
		-moz-box-shadow: inset 0px 0px 55px 10px rgba(222, 222, 222, 1);
		box-shadow: inset 0px 0px 55px 10px rgba(222, 222, 222, 1);
		cursor: pointer;
	}

	&:hover.disabled {
		cursor: not-allowed;
		filter: grayscale(100%);
	}
}

.smooth-vuebar {
	height: 100%;
}

.pop-up-content {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	padding: 1rem 0.5rem;

	&.otp-popup {
		flex-direction: column;
		>:first-child {
			display: flex;
			flex-direction: row;
			> :first-child {
				flex: 6;
			}

			> :last-child {
				flex: 4;
			}
		}
	}
}

.vs-popup {
	width: 760px;
}

.checkbox {
	span {
		font-size: 16px;
	}

	i {
		font-size: 16px;
	}
}

.footer-actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

@media only screen and (max-width: 768px) {
	.content {
		flex-direction: column;
	}

	.file-reader {
		height: auto;
		width: 100%;
	}

	.select-file {
		flex-direction: row;
		width: 100%;
		height: auto;
		padding: 1rem 0;
	}
}
</style>
